import React from 'react';

function Target() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        <div className="py-12 md:py-20 border-t border-gray-800">
          

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="font-architects-daughter text-purple-600 h2 mb-4">Implementation</h2>
            <h1 className="h2 mb-4" data-aos="fade-up" data-aos-delay="200">Cosmo meets you where you are</h1>
          </div>

          {/* Items */}
          <div className="grid gap-20" data-aos-id-target>

            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-right" data-aos-delay="200" data-aos-anchor="[data-aos-id-target]">
                <img className="mx-auto md:max-w-none" src={require('../images/skyscraper-90560_1920_2.jpg')} width="540" height="405" alt="Features 02" />
              </div>

              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2" data-aos="fade-left" data-aos-anchor="[data-aos-id-target]">Implementation options include:</div> */}
                  <div className="mt-6" data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-target]">
                  <div className="font-architects-daughter text-purple-600  h3 mb-3" data-aos="fade-left" data-aos-anchor="[data-aos-id-target]">Options include:</div>
                    <h4 className="h3 mb-3"><span className="text-purple-600">.</span> Cloud Managed</h4>
                    <p className="text-xl text-gray-400 mb-4">Don't worry about maintenance or uptime, just take advantage of all the benefits, and let us do the rest.</p>
                  </div>
                  <div className="mt-6" data-aos="fade-left" data-aos-delay="400" data-aos-anchor="[data-aos-id-target]">
                    <h4 className="h3 mb-3"><span className="text-teal-500">.</span> On Prem</h4>
                    <p className="text-xl text-gray-400 mb-4">Flexible enough to be deployed on premises.</p>
                  </div>
                  <div className="mt-6" data-aos="fade-left" data-aos-delay="600" data-aos-anchor="[data-aos-id-target]">
                    <h4 className="h3 mb-3"><span className="text-pink-500">.</span> Onboard at your own Speed</h4>
                    <p className="text-xl text-gray-400 mb-4">Add features as needed.</p>
                  </div>
                  <div className="mt-6" data-aos="fade-left" data-aos-delay="600" data-aos-anchor="[data-aos-id-target]">
                    <h4 className="h3 mb-3"><span className="text-blue-500">.</span> Email for More Information</h4>
                    <p className="text-xl text-gray-400 mb-4">info@cosmodev.tech</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Target;
