import React from 'react';

function FeaturesZigzag() {
  return (
<section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <div className="font-architects-daughter text-purple-600 h2 mb-4">Deliver Products Faster</div>
                <h1 className="h2 mb-4" data-aos="fade-up" data-aos-delay="200">Cosmo makes it all possible, from idea to production</h1>
                {/* <p className="text-xl text-gray-400">From idea to production</p> */}
                {/* <p className="text-xl text-gray-400">Based off industry leading open source projects</p>
                <p className="text-xl text-gray-400">Providing a platform to help you deliver value faster</p> */}
            </div>

            {/* Items */}
            <div className="grid gap-20">

                {/* 1st item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    {/* Image */}
                    {/* <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/devops-3148393_1920.png')} width="540" height="405" alt="Features 01" />
                    </div> */}
                    {/* Content */}
                    {/* <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                            <div className="font-architects-daughter text-xl text-purple-600 mb-2">From idea to deployment</div>
                            <h3 className="h3 mb-3">Devops</h3>
                            <p className="text-xl text-gray-400 mb-4">Make it easy to develop anywhere and have the tools to deploy everywhere</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>All tools works together</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Flexible enough to work with almost any workflow</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Tools selected to create a seamless workflow</span>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>

                {/* 2nd item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    {/* Image */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/code-1839406_1920.jpg')} width="540" height="405" alt="Features 02" />
                    </div>
                    {/* Content */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                        <div className="md:pl-4 lg:pl-12 xl:pl-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Develop anywhere</div>
                            <h3 className="h3 mb-3">Web based IDE</h3>
                            <p className="text-xl text-gray-400 mb-4">Easily provide consistent work environments on any device to all your developers, freeing their time to add value.</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Consistent work environment</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Develop anywhere on any device</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Centralized maintenance</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* 3rd item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    {/* Image */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/pexels-manuel-geissinger-325229_3.jpg')} width="540" height="405" alt="Features 03" />
                    </div>
                    {/* Content */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Faster builds for everyone</div>
                            <h3 className="h3 mb-3">Remote Execution and Cache</h3>
                            <p className="text-xl text-gray-400 mb-4">Share a cluster of remote execution machines making all developers and CI builds faster</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Save money by decreasing hardware spending and increasing developer speed</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Scale your builds across as many machines as possible</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Don't rebuild pieces that haven't changed</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* 4nd item */}
                {/* <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/speedometer-653246_1920.jpg')} width="540" height="405" alt="Features 02" />
                    </div>
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                        <div className="md:pl-4 lg:pl-12 xl:pl-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Don't waste developer time</div>
                            <h3 className="h3 mb-3">Faster Builds</h3>
                            <p className="text-xl text-gray-400 mb-4">Speed up builds across your workflow getting faster feedback for developers and saving development time</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Increases build speed by up to 10x or more</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Parallel benifits for developers and CI</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Save money by increasing developer speed</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}



                {/* 5rd item */}
                {/* <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/plumbing-1103725_1920.jpg')} width="540" height="405" alt="Features 03" />
                    </div>
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Create pipelines that fit your workflow</div>
                            <h3 className="h3 mb-3">Flexible CI/CD</h3>
                            <p className="text-xl text-gray-400 mb-4">Pipelines that can grow with you from simple to complex</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Flexible pipelines for both CI and CD</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Flexible enough to meet the needs of multiple teams</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Ensures speed and stability</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}


                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/plumbing-1103725_1920_2.jpg')} width="540" height="405" alt="Features 02" />
                    </div>
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                        <div className="md:pl-4 lg:pl-12 xl:pl-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Create pipelines that fit your workflow</div>
                            <h3 className="h3 mb-3">Flexible CI/CD</h3>
                            <p className="text-xl text-gray-400 mb-4">Pipelines that can grow with you from simple to complex</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Flexible pipelines for both CI and CD</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Flexible enough to meet the needs of multiple teams</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Ensures speed and stability</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* 4nd item */}
                {/* <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/speedometer-653246_1920.jpg')} width="540" height="405" alt="Features 02" />
                    </div>
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                        <div className="md:pl-4 lg:pl-12 xl:pl-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Don't waste developer time</div>
                            <h3 className="h3 mb-3">Faster Builds</h3>
                            <p className="text-xl text-gray-400 mb-4">Speed up builds across your workflow getting faster feedback for developers and saving development time</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Increases build speed by up to 10x or more</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Parallel benifits for developers and CI</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Save money by increasing developer speed</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}


                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/speedometer-653246_1920_2.jpg')} width="540" height="405" alt="Features 03" />
                    </div>
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                            <div className="font-architects-daughter text-purple-600 h3 mb-3">Don't waste developer time</div>
                            <h3 className="h3 mb-3">Faster Builds</h3>
                            <p className="text-xl text-gray-400 mb-4">Speed up builds across your workflow getting faster feedback for developers and saving development time</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Increases build speed by up to 10x or more</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Parallel benifits for developers and CI</span>
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Save money by increasing developer speed</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>







            </div>

        </div>
    </div>
</section>
  );
}

export default FeaturesZigzag;